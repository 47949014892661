import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { ArrowPanel, IArrowPanelProps, IconComponent } from "@app/core";
import { FeaturedCategoriesModule } from "@app/api/modules/featured-categories/featured-categories.module";
import Typography from "@app/components/typography";
import ChevronLeftIcon from "@assets/icons/chevron-left.svg";
import ChevronRightIcon from "@assets/icons/chevron-right.svg";
import Flicking, { ChangedEvent, DIRECTION, ReachEdgeEvent } from "@egjs/react-flicking";

import styles from "./featured-categories-component.module.scss";

export interface IFeaturedCategoriesProps {
  featuredCategoriesModule: FeaturedCategoriesModule;
}

export const FeaturedCategories = (props: IFeaturedCategoriesProps) => {
  const { categoryItems, title } = props.featuredCategoriesModule;
  const carousel = useRef<Flicking>(null);

  const [showControls, setShowControls] = useState<boolean>(true);
  const [showNextButton, setShowNextButton] = useState<boolean>(true);
  const [showPrevButton, setShowPrevButton] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window === "object") {
      const handleResize = () => getCarouselView(window.innerWidth);
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  });

  useEffect(() => {
    if (typeof window === "object") {
      getCarouselView(window.innerWidth);
    }
  }, []);

  const getCarouselView = (width: number): void => {
    let slides = 5;
    if (width < carouselBreakPoints.mobileOneSlide) {
      slides = 1;
    } else if (width < carouselBreakPoints.mobileTwoSlides) {
      slides = 2;
    } else if (width < carouselBreakPoints.mobileThreeSlides) {
      slides = 3;
    } else if (width < carouselBreakPoints.mobileFourSlides) {
      slides = 4;
    } else if (width < carouselBreakPoints.desktopTwoSlides) {
      slides = 2;
    } else if (width < carouselBreakPoints.desktopThreeSlides) {
      slides = 3;
    } else if (width < carouselBreakPoints.desktopFourSlides) {
      slides = 4;
    }

    setShowControls(slides < categoryItems.length);
  };

  const showFullCards = categoryItems.some(ci => (ci.image || ci.fallbackImgUrl) && ci.link.title.length > 0);

  const selectedItemChanged = (e: ChangedEvent) => {
    if (!showNextButton && e.index < e.prevIndex) {
      setShowNextButton(true);
    }
    if (!showPrevButton && e.index > e.prevIndex) {
      setShowPrevButton(true);
    }
  };

  const edgeReached = (e: ReachEdgeEvent) => {
    if (e.direction === DIRECTION.NEXT) {
      setShowNextButton(false);
    }
    if (e.direction === DIRECTION.PREV) {
      setShowPrevButton(false);
    }
  };

  return (
    <div className={styles.featuredCategories}>
      <div className="uk-container">
        {title && <Typography tag="h2">{title}</Typography>}
        <div className={styles.wrapper} data-uk-margin>
          <>
            {showControls && showPrevButton && (
              <div className={clsx(styles.buttonWrapper)}>
                <button type="button" className={styles.sliderButton} onClick={() => carousel?.current?.prev()}>
                  <div className={styles.icon}>
                    <IconComponent icon={ChevronLeftIcon} strokeColor="white" size="14px" />
                  </div>
                </button>
              </div>
            )}
            <Flicking
              viewportTag="div"
              cameraTag="div"
              cameraClass={styles.slider}
              horizontal={true}
              bound
              moveType={"freeScroll"}
              preventClickOnDrag
              preventDefaultOnDrag
              align={"prev"}
              inputType={["touch", "mouse"]}
              ref={carousel}
              onChanged={selectedItemChanged}
              onReachEdge={edgeReached}
            >
              {categoryItems?.map((item, index) => (
                <div key={index} className={styles.category}>
                  <ArrowPanel
                    {...item}
                    link={{
                      title: item.link.title,
                      url: item.link.url,
                      newTab: item.link.url.includes("/out/category/") || item.link.newTab
                    }}
                    rounded
                    variant={showFullCards ? "categoryBig" : "categorySmall"}
                  />
                </div>
              ))}
            </Flicking>
            {showControls && showNextButton && (
              <div className={clsx(styles.buttonWrapper, styles.buttonWrapperNext)}>
                <button type="button" className={styles.sliderButton} onClick={() => carousel?.current?.next()}>
                  <div className={styles.icon}>
                    <IconComponent icon={ChevronRightIcon} strokeColor="white" size="14px" />
                  </div>
                </button>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

const carouselBreakPoints = {
  mobileOneSlide: 330,
  mobileTwoSlides: 480,
  mobileThreeSlides: 620,
  mobileFourSlides: 640,
  desktopTwoSlides: 710,
  desktopThreeSlides: 920,
  desktopFourSlides: 1150,
  desktopFiveSlides: 1400
};
