import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { IconComponent, ImageComponent } from "@app/core";
import Typography from "@app/components/typography";
import ChevronLeftIcon from "@assets/icons/chevron-left.svg";
import ChevronRightIcon from "@assets/icons/chevron-right.svg";
import Flicking, { ChangedEvent, DIRECTION, ReachEdgeEvent } from "@egjs/react-flicking";

import { ICategoryBlocksModule } from "@app/api/modules/category-blocks/category-blocks.modules";

import styles from "./category-blocks-component.module.scss";

export interface ICategoryBlocksProps {
  categoryBlocksModule: ICategoryBlocksModule;
}

export const CategoryBlocks = (props: ICategoryBlocksProps) => {
  const { categoryBlocks: categoryItems, moduleTitle: title } = props.categoryBlocksModule;
  const carousel = useRef<Flicking>(null);

  const [showControls, setShowControls] = useState<boolean>(true);
  const [showNextButton, setShowNextButton] = useState<boolean>(true);
  const [showPrevButton, setShowPrevButton] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window === "object") {
      const handleResize = () => getCarouselView(window.innerWidth);
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  });

  useEffect(() => {
    if (typeof window === "object") {
      getCarouselView(window.innerWidth);
    }
  }, []);

  const getCarouselView = (width: number): void => {
    let slides = 5;
    if (width < carouselBreakPoints.mobileOneSlide) {
      slides = 1;
    } else if (width < carouselBreakPoints.mobileTwoSlides) {
      slides = 2;
    } else if (width < carouselBreakPoints.mobileThreeSlides) {
      slides = 3;
    } else if (width < carouselBreakPoints.mobileFourSlides) {
      slides = 4;
    } else if (width < carouselBreakPoints.desktopTwoSlides) {
      slides = 2;
    } else if (width < carouselBreakPoints.desktopThreeSlides) {
      slides = 3;
    } else if (width < carouselBreakPoints.desktopFourSlides) {
      slides = 4;
    }

    setShowControls(slides < categoryItems.length);
  };

  const selectedItemChanged = (e: ChangedEvent) => {
    if (!showNextButton && e.index < e.prevIndex) {
      setShowNextButton(true);
    }
    if (!showPrevButton && e.index > e.prevIndex) {
      setShowPrevButton(true);
    }
  };

  const edgeReached = (e: ReachEdgeEvent) => {
    if (e.direction === DIRECTION.NEXT) {
      setShowNextButton(false);
    }
    if (e.direction === DIRECTION.PREV) {
      setShowPrevButton(false);
    }
  };

  return (
    <div className={clsx("uk-container", styles.categoryBlocks)}>
      {title && <Typography tag="h2">{title}</Typography>}
      <div className={styles.wrapper} data-uk-margin>
        <>
          {showControls && showPrevButton && (
            <button type="button" className={clsx(styles.sliderButton)} onClick={() => carousel?.current?.prev()}>
              <div className={styles.icon}>
                <IconComponent icon={ChevronLeftIcon} strokeColor="white" size="14px" />
              </div>
            </button>
          )}
          <Flicking
            viewportTag="div"
            cameraTag="div"
            cameraClass={styles.slider}
            horizontal={true}
            bound
            moveType="freeScroll"
            preventClickOnDrag
            preventDefaultOnDrag
            align="prev"
            inputType={["touch", "mouse"]}
            ref={carousel}
            onChanged={selectedItemChanged}
            onReachEdge={edgeReached}
          >
            {categoryItems.map((category, index) => (
              <div tabIndex={-1} key={index}>
                <div className={styles.categoryContainer}>
                  <Link to={category.categoryLink?.url || ""}>
                    <div className={styles.categoryimage}>
                      <ImageComponent
                        width="128px"
                        src={category.categoryImage?.url}
                        alt={category.categoryImage?.alt}
                        title={category.categoryImage?.title}
                      />
                    </div>
                  </Link>
                  <div className={styles.categoryTitle}>{category.categoryTitle}</div>
                </div>
              </div>
            ))}
          </Flicking>
          {showControls && showNextButton && (
            <button
              type="button"
              className={clsx(styles.sliderButton, styles.sliderButtonNext)}
              onClick={() => carousel?.current?.next()}
            >
              <div className={styles.icon}>
                <IconComponent icon={ChevronRightIcon} strokeColor="white" size="14px" />
              </div>
            </button>
          )}
        </>
      </div>
    </div>
  );
};

const carouselBreakPoints = {
  mobileOneSlide: 330,
  mobileTwoSlides: 480,
  mobileThreeSlides: 620,
  mobileFourSlides: 640,
  desktopTwoSlides: 710,
  desktopThreeSlides: 920,
  desktopFourSlides: 1150,
  desktopFiveSlides: 1400
};
